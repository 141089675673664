										 


/*COLOURS

main - #0f1324 - #121627- #f5f5f5
header/boxes - #121627 - #efefef
green-main - #76af2a
green-dark-hover - #527b1c
green-light-create-account - #80d60c

*/

html {
    font-size: 16px
}

body {
    position: relative;
    background: #f5f5f5;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #f5f5f5;
}

body:before,
body:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: auto;
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 0.6
}

.container {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding-right: 15px;
    padding-left: 15px
}


/* header image testing */

.header-index.nfl {
    display: block;
    background-image: linear-gradient(rgba(28, 36, 44, 0.5),rgba(28, 36, 44, 0.5)), url(/images/resources/nfl-background-image.jpg);
    background-size: cover;
    background-position: top;
    height: 300px;
}

.header-index.ncaaf {
    display: block;
    background-image: linear-gradient(rgba(28, 36, 44, 0.5),rgba(28, 36, 44, 0.5)), url(/images/resources/ncaaf-background-image.jpg);
    background-size: cover;
    background-position: top;
    height: 300px;
}

.header-index.ufc {
    display: block;
    background-image: linear-gradient(rgba(28, 36, 44, 0.5),rgba(28, 36, 44, 0.5)), url(/images/resources/ufc-background-image.jpg);
    background-size: cover;
    background-position: top;
    height: 400px;
}


.header-index.nba {
    display: block;
    background-image: linear-gradient(rgba(28, 36, 44, 0.5),rgba(28, 36, 44, 0.5)), url(/images/resources/nba-background-image.jpg);
    background-size: cover;
    background-position: top;
    height: 300px;
}

.header-index.mlb {
    display: block;
    background-image: linear-gradient(rgba(28, 36, 44, 0.5),rgba(28, 36, 44, 0.5)), url(/images/resources/mlb-background-image.jpg);
    background-size: cover;
    background-position: top;
    height: 300px;
}

.header-index.nhl {
    display: block;
    background-image: linear-gradient(rgba(28, 36, 44, 0.5),rgba(28, 36, 44, 0.5)), url(/images/resources/nhl-background-image.jpg);
    background-size: cover;
    background-position: top;
    height: 300px;
}

.header-index.football {
    display: block;
    background-image: linear-gradient(rgba(28, 36, 44, 0.5),rgba(28, 36, 44, 0.5)), url(/images/resources/football-background-image.jpg);
    background-size: cover;
    background-position: top;
    height: 300px;
}


.hero-text-box {
    max-width:  1140px;
    margin: auto;
    padding: 8%;
    padding-top: 2%;
}

h3 {
    margin: 50px 0 0 0;
    color: #f5f5f5;
    font-size: 250%;
    font-weight: 400;
    letter-spacing: 1px;
    word-spacing: 1px;
}

h4 {
    margin-top: 3px;
    margin-bottom: 15px;
    color: #f5f5f5;
    font-size: 125%;
    font-weight: 400;
}


@media (max-width:576px) {
    .header-index {height: 200px;}
    .hero-text-box { text-align: center;}
    h3 {font-size:200%;
        margin-top: 25px;}
    h4 {font-size:90%;}
}

.img-record {
    filter: invert(78%) sepia(89%) saturate(7496%) hue-rotate(360deg) brightness(112%) contrast(122%);
}

.img-watch {filter: invert(21%) sepia(15%) saturate(6473%) hue-rotate(38deg) brightness(89%) contrast(67%);}

.img-stream {filter: invert(80%) sepia(16%) saturate(5215%) hue-rotate(208deg) brightness(97%) contrast(92%);}

/*-------------*/

.container--thin {
    max-width: 800px
}

section {
    padding: 4em 0
}

@media (max-width:768px) {
    section {
        padding: 1em 0
    }
}

img {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

button,
a {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.divider {
    width: 100%;
    height: 1px;
    background-color: rgba(247, 251, 255, 0.1)
}

.logo-image {
    width: 5.5em;
    margin-right: 10px;
}

.logo {
    text-decoration: none
}

.logo:hover,
.logo:focus {
    text-decoration: none
}

.logo h1 {
    margin: 0;
    line-height: 1;
    color: #f7fbff;
    text-transform: uppercase;
    font-family: 'Roboto Condensed';
    font-size: 1.3em;
}

@media (max-width:576px) {
    .logo h1 {
        font-size: 0.8em
    }
}

@media (max-width:400px) {
    .logo h1 {
        font-size: 0.65em
    }
}

.img-channel {
    filter: invert(1);
    max-width: 80px;
    opacity: 0.5
}

input,
label {
    display: block;
    width: 100%
}

label {
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.95em
}

input {
    color: rgba(0, 0, 0, 0.9);
    background-color: #f5f5f5;
    border: 1px solid transparent;
    padding: 0.75rem;
    height: 50px;
    margin-bottom: 1rem;
    transition: 0.2s border ease-in-out
}

input:focus,
inputactive {
    border-color: #f5f5f5;
    outline-color: transparent;
    outline-offset: unset
}

input.invalid {
    border-color: #fc0017
}

.label-note {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.25);
    cursor: pointer
}

.label-note:hover {
    color: rgba(255, 255, 255, 0.5)
}

.form-alert {
    font-size: 0.85rem;
    padding: 0.75rem;
    background: rgba(252, 0, 23, 0.4);
    text-align: center;
}

.form-alert a {
    color: #76af2a
}

.form-alert a:hover,
.form-alert a:focus {
    color: #76af2a
}

.btn {
    border: none;
    font-size: 0.7rem;
    padding: 0.5rem 1.5rem;
    text-transform: uppercase;
    line-height: 1
}

@media (max-width:576px) {
    .btn {
        padding: 0.5rem 0.875rem
    }
}

.btn-primary {
    background-color: #76af2a;
    color: #f5f5f5
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active {
    background-color: #80d60c;
    border: none;
    box-shadow: none
}

.btn-secondary {
    color: #121627;
    opacity: 0.65;
    background-color: transparent;
    border: 1px solid #121627;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled):active {
    color:red;
    opacity: 1;
    border: 1px solid #121627;
    box-shadow: none;
    background-color: transparent;
}

.btn-outline {
    border: 1px solid #76af2a;
    color: #f5f5f5;
    background-color: transparent;
}

.btn-outline:hover,
.btn-outline:focus {
    border: 1px solid #76af2a;
    color: #f5f5f5;
    box-shadow: none;
    background-color: #76af2a;
}

.btn-shade {
    background-color: #192029;
    color: #f7fbff
}

.btn-shade:hover,
.btn-shade:focus {
    background-color: #17242d;
    color: #f7fbff;
    box-shadow: none;
}

.btn-lg {
    padding: 0.8em 2em;
    font-size: 1.25em;
    line-height: 1.5;
}

@media (max-width:576px) {
    .btn-lg {
        font-size: 1em;
    }
}

.btn-md {
    padding: 0.8em 2em;
    font-size: 1em;
    line-height: 1;
}

@media (max-width:576px) {
    .btn-md {
        font-size: 0.9em;
    }
}

.btn-sm {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
}

.live-badge {
    display: inline-flex;
    align-items: center;
    padding: 0.35em 0.5em;
    border: 1px solid red;
    border-radius: 0.25rem;
    color:red;
}

.live-badge span {
    font-size: 0.7em;
    line-height: 1;
    text-transform: uppercase;
}

.live-badge__icon {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 0.25em;
    background-color: #fc0017;
    border-radius: 50%;
    -webkit-animation-name: pulse;
    -webkit-animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.headline {
    font-family: 'Roboto Condensed';
    color: #f5f5f5;
    font-size: 1.95em;
    margin-bottom: 0.5em;
}

@media (max-width:576px) {
    .headline {
        font-size: 1.5em;
    }
}

.title-large {
    color: #f5f5f5;
    font-size: 3em;
    margin-bottom: 0.5em;
}

.title-medium {
    color: #f5f5f5;
    font-size: 1.7em;
    margin-bottom: .5em;
    margin-top: .5em;
}

.title-small {
    color: #f5f5f5;
    font-size: 1em;
    margin-bottom: .5em;
    margin-top: .5em;
}

.title {
    color: #121627;
    font-size: 1.25em;
    margin-bottom: 0.5em;
}


.text {
    color: #9a9a9a;
    font-size: 1em;
    margin-bottom: 0.5em;
}

.text-muted {
    font-size: 0.925em;
    color: rgba(255, 255, 255, 0.5);
}

.text-club-info {
      font-size: 0.925em;
    color: rgba(255, 255, 255, 0.7);
}

.text-right{color:red;}					   

.nav {
    width: 100%;
    background-color: #121627;
}

.nav__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
}

.nav .btn {
    margin: 0 0.5em;
}

.nav .btn:last-child {
    margin-right: 0;
}

.nav__navigation {
    align-self: flex-end;
}

@media (max-width:576px) {
    .nav__navigation #browse {
        display: none;
    }
}

.nav-teams {
    background-color: #f5f5f5;
}

@media (max-width:992px) {
    .nav-teams {
        overflow: hidden;
    }
}

.nav-teams__inner {
    padding: 0.85em 0;
    display: flex;
}

@media (max-width:992px) {
    .nav-teams__inner {
        overflow-x: auto;
        -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0));
        -webkit-mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
    }
}

.nav-teams__inner a {
    width: 100%;
    display: inline-block;
    margin: 0 0.15em;
}

.nav-teams__inner a img {
    width: 100%;
    height: 32.2px;
    transition: transform 0.2s ease-in-out;
}

@media (max-width:992px) {
    .nav-teams__inner a img {
        width: 45px;
        max-width: unset;
    }
}

.nav-teams__inner a:hover img {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
}

@media (max-width:992px) {
    .nav-teams {
        display: none;
    }
}

#signin {color:f5f5f5;}

.signin {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 998;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 460px;
    background-color: #121627;
    padding: 1rem;
    box-shadow: 10px 0 50px #030506;
    transition: 0.5s transform cubic-bezier(1, 0, 0, 1);
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
}

.signin.open {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    transition: 0.5s transform cubic-bezier(1, 0, 0, 1);
}

.signin__holder {
    width: 100%;
    max-width: 320px;
}

.signin__close {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    display: block;
}

.signin h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.signin__group {
    position: relative;
    width: 100%;
}

.signin__footer {
    text-align: center;
    margin-top: 1rem;
    padding: 1rem;
}

.signin__footer > a {
    color: rgba(255, 255, 255, 0.25);
    cursor: pointer;
}

.signin__footer > a span {
    color: rgba(255, 255, 255, 0.5);
}

.signin button {
    width: 100%;
}

.channels .channel-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #efefef;
    padding: 1em;
}

@media (max-width:576px) {
    .channels .channel-list {
        flex-wrap: nowrap;
        flex-direction: column;
    }
}

.channels .channel-list li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
}

@media (max-width:576px) {
    .channels .channel-list li {
        flex-direction: row;
    }
}

.channels .channel-list__left {
    display: flex;
    align-items: center;
}

.channels .channel-list__left a {
    min-height: 60px;
    line-height: 60px;
}

@media (max-width:576px) {
    .channels .channel-list__left a {
        min-height: unset;
        line-height: 1;
    }
}

.channels .channel-list__left a img {
    width: 100%;
    max-width: 100px;
	max-height: 50px;				 
    opacity: 0.9;
    margin-bottom: 1em;
}

@media (max-width:576px) {
    .channels .channel-list__left a img {
        margin-bottom: 0;
    }
}

.games-live {
    margin-top: 2em;
	color:#76af2a;			  
}

.games-live__list {
    padding: 0;
    margin: 0;
}

.games-live .game-live {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
}

.games-live .game-live__banner {
    display: flex;
    width: 100%;
    background-color: #ebebeb;
    padding: 0.25em 1em;
}



.games-live .game-live__scores {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 50%;
}

@media (max-width:576px) {
    .games-live .game-live__scores {
        width: 65%;
    }


}

.games-live .game-live__scores__score {
    margin: 0;
}

.games-live .game-live__status {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


@media (max-width:576px) {
    .games-live .game-live__status {
        width: 35%;
    }
}

.games-live .game-live__main {
    display: flex;
    align-items: center;
    padding: 1em;
    background-color: #efefef;
}

.games-live .game-live__teams {
    display: flex;
    align-items: center;
    width: 50%;
}

@media (max-width:576px) {
    .games-live .game-live__teams {
        width: 65%;
    }
}

.games-live .game-live__teams__holder {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.games-live .game-live__teams__holder > a {
    color: #fff;
    display: block;
    text-align: center;
}

.games-live .game-live__teams__holder > a:hover,
.games-live .game-live__teams__holder > a:focus {
    text-decoration: none;
}

.games-live .game-live__teams__holder > a img {
    width: 100%;
    max-width: 60px;
    margin-bottom: 0.5em;
    max-height: 60px;
}

.games-live .game-live__teams__score {
    margin: 0;
}

.games-live .game-live__teams__name {
    font-size: 1em;
    margin: 0 0 0 0.5em;
    color:#0f1324;		  
}

@media (max-width:768px) {
    .games-live .game-live__teams__name {
        font-size: 0.8em;
    }
}

.games-live .game-live__teams__vs {
    display: block;
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.5);
}

.games-live .game-live__cta {
    width: 20%;
    text-align: right;
}

.games-live .game-live__cta i {
    margin-left: 0.25em;
}

@media (max-width:768px) {
    .games-live .game-live__cta {
        width: 50%;
    }
}

@media (max-width:576px) {
    .games-live .game-live__cta {
        width: 35%;
    }
}

.games-live .game-live__channel {
    width: 30%;
    text-align: center;
}

@media (max-width:768px) {
    .games-live .game-live__channel {
        display: none !important;
    }
}

.games-upcoming {
    margin-top: 2em;
}

.games-upcoming__list {
    padding: 0;
    margin: 0;
}

.games-upcoming .game-upcoming {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1em;
    margin-top: 0.5em;
    background-color: #efefef;
}

@media (max-width:576px) {
    .games-upcoming .game-upcoming {
        flex-direction: column;
    }
}

.games-upcoming .game-upcoming > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.games-upcoming .game-upcoming > div:first-child {
    align-items: flex-start;
}

.games-upcoming .game-upcoming > div:last-child {
    align-items: flex-end;
}

.games-upcoming .game-upcoming__teams {
    width: 50%;
}

@media (max-width:768px) {
    .games-upcoming .game-upcoming__teams {
        width: 70%;
    }
}

@media (max-width:576px) {
    .games-upcoming .game-upcoming__teams {
        width: 100%;
    }
}

.games-upcoming .game-upcoming__teams__holder {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 1em;
}

.games-upcoming .game-upcoming__teams__holder > a {
    color: #121627;
    display: block;
    width: 60%;
}

.games-upcoming .game-upcoming__teams__holder > a:hover,
.games-upcoming .game-upcoming__teams__holder > a:focus {
    text-decoration: none;
}

.games-upcoming .game-upcoming__teams__holder > a img {
    width: 100%;
    max-width: 30px;
    max-height: 30px;
}

.games-upcoming .game-upcoming__teams__name {
    display: inline-block;
    font-size: 1em;
    margin: 0 0 0 0.25em
}

@media (max-width:768px) {
    .games-upcoming .game-upcoming__teams__name {
        font-size: 0.8em
    }
}

.games-upcoming .game-upcoming__teams__time,
.games-upcoming .game-upcoming__teams__date {
    color: #76af2a;
    text-align: center;
    text-transform: uppercase;
    width: 40%;
    margin: 0
}

@media (max-width:768px) {

    .games-upcoming .game-upcoming__teams__time,
    .games-upcoming .game-upcoming__teams__date {
        text-align: right
    }
}

@media (max-width:576px) {

    .games-upcoming .game-upcoming__teams__time,
    .games-upcoming .game-upcoming__teams__date {
        font-size: 0.8em
    }
}

.games-upcoming .game-upcoming__teams__date {
    font-size: 0.8em;
    color: #76af2a
}

.games-upcoming .game-upcoming__teams__vs {
    display: block;
    font-size: 0.8em;
    margin-left: 3em;
    color: rgba(255, 255, 255, 0.5)
}

.games-upcoming .game-upcoming__channel {
    width: 30%
}

.games-upcoming .game-upcoming__channel img {
    max-width: 80px;
    opacity: 0.3
}

@media (max-width:768px) {
    .games-upcoming .game-upcoming__channel {
        display: none !important
    }
}

.games-upcoming .game-upcoming__cta {
    color: #f5f5f5;
    text-align: right;
    width: 20%;
}

@media (max-width:768px) {
    .games-upcoming .game-upcoming__cta {
        width: 30%
    }
}

@media (max-width:576px) {
    .games-upcoming .game-upcoming__cta {
        width: 100%
    }

    .games-upcoming .game-upcoming__cta > div {
        width: 100%;
        margin-top: 1em
    }

    .games-upcoming .game-upcoming__cta > div .btn {
        width: 100%
    }
}

.games-scores {
    margin-top: 2em
}

.games-scores__list {
    padding: 0;
    margin: 0
}

.games-scores .game-score {
    display: flex;
    align-items: center;
    padding: 0.25em;
    margin-bottom: 0.25em;
    border-bottom: 1px solid rgba(247, 251, 255, 0.1)
}

.games-scores .game-score .g-score {
    width: 100%;
    text-decoration: none;
}

.games-scores .game-score:last-child {
    border-bottom: none
}

.games-scores .game-score__teams {
    width: 100%
}

.games-scores .game-score__teams__holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%
}

.games-scores .game-score__teams__holder > .g-holder {
    width: 70%;
    color: #121627;
    display: block
}

.games-scores .game-score__teams__holder > .g-holder:hover,
.games-scores .game-score__teams__holder > .g-holder:focus {
    text-decoration: none
}

.games-scores .game-score__teams__holder > .g-holder img {
    width: 100%;
    max-width: 30px;
    max-height: 30px;
}

/* .games-scores .game-score__teams__holder > a {
    width: 70%;
    color: #121627;
    display: block
}

.games-scores .game-score__teams__holder > a:hover,
.games-scores .game-score__teams__holder > a:focus {
    text-decoration: none
}

.games-scores .game-score__teams__holder > a img {
    width: 100%;
    max-width: 30px
} */

.games-scores .game-score__teams__info {
    display: flex;
    justify-content: space-between;
    width: 30%;
    align-items: center
}

.games-scores .game-score__teams__score {
    color:#76af2a;
    font-size: 0.85em;
    margin: 0
}

.games-scores .game-score__teams__name {
    display: inline-block;
    font-size: .73em;
}

.games-scores .game-score__teams__span,
.games-scores .game-score__teams__date {
    text-transform: uppercase;
    font-size: 0.7em;
    color: #76af2a;
    margin: 0
}

.player {
    position: relative;
    z-index: 5;
    -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.35);
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.35)
}

.player:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 54%
}

.player__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #0c1117;
    z-index: 10
}

.player__period {
    position: absolute;
    left: 1em;
    right: 1em;
    z-index: 10
}

.player__live {
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 10
}

.player__preloader {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 5
}

.player__preloader .preloader {
    position: absolute;
    width: 100%;
    max-width: 480px
}

.player__preloader span {
    display: block;
    font-size: 0.875em;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    margin-top: 1em
}

.player__poster {
    color: #f7f7f7;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #0c1117;
    z-index: 5
}

.player__poster__state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%
}

@media (max-width:992px){

    .player__poster__cover__teams > img:first-child {
        left: 13%;
        top: 0%;
        max-width: 6.5em;
    }
    
    .player__poster__cover__teams > img:last-child {
        right: 13%;
        top: 0%;
        max-width: 6.5em;
    }
}

@media (max-width:768px){

    .games-scores .game-score__teams__name {
        display: inline-block;
        font-size: 1.2em;
    }

    .player__poster__cover__teams > img:first-child {
        left: 4%;
        top: -2%;
        max-width: 5.5em;
    }
    
    .player__poster__cover__teams > img:last-child {
        right: 4%;
        top: -2%;
        max-width: 5.5em;
    }
}

@media (max-width:576px) {
    .player__poster__state > p {
        font-size: 0.85em
    }

    .player__poster__cover__teams > img:first-child {
        left: 4%;
        top: -6%;
        max-width: 5.5em;
    }
    
    .player__poster__cover__teams > img:last-child {
        right: 4%;
        top: -6%;
        max-width: 5.5em;
    }
}

@media (max-width:414px){

    .player__poster__cover__teams > img:first-child {
        left: 4%;
        top: -6%;
        max-width: 5.5em;
    }
    
    .player__poster__cover__teams > img:last-child {
        right: 4%;
        top: -6%;
        max-width: 5.5em;
    }
}

.player__poster__cta {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    color: #121627;
    text-transform: uppercase;
    cursor: pointer
}

.player__poster__cta:hover,
.player__poster__cta:focus {
    text-decoration: none;
    color: #121627
}

.player__poster__cta__play-icon {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0.35em 1.5em;
    margin: 0 0.5em;
    border-radius: 5px;
    border: 2px solid #76af2a;
    -webkit-transition: border 0.3s ease-in-out;
    transition: border 0.3s ease-in-out
}

.player__poster__cta__play-icon > i {
    font-size: 1.25em;
    -webkit-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out
}

.player__poster__cta__play-icon:hover,
.player__poster__cta__play-icon:focus {
    border: 2px solid #80d60c
}

.player__poster__cta__play-icon:hover > i,
.player__poster__cta__play-icon:focus > i {
    -webkit-transform: scale(0.9);
    transform: scale(0.9)
}

.player__poster__cover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat
}

.player__poster__cover__overlay {
    position: relative;
    height: 100%
}

.player__poster__cover__overlay:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background: rgba(12, 17, 23, 0.5)
}

.player__poster__cover__overlay.channel:before {
    background: rgba(12, 17, 23, 0.8)
}

/* .player__poster__cover__score { */
    /* position: absolute; */
    /* top: 40px; */
    /* width: 100%; */
    /* left: 50%; */
    /* -webkit-transform: translateX(-50%); */
    /* transform: translateX(-50%); */
    /* text-align: center; */
    /* color: white; */
/* } */

@media (max-width:768px) {
    .player__poster__cover__score img {
        max-height: 5.5em;
        max-width: 5.5em;
    }

    .player__poster__cover__score_boxing img {
        max-height: 5.5em;
        max-width: 4.5em;
    }

    .player__poster__cover__score {
        top: 20px
    }

    .player__poster__cover__score_boxing {
        top: 20px
    }
}

.player__poster__cover__teams {
    /* position: absolute; */
    /* top: 0; */
    /* right: 0; */
    /* left: 0; */
    /* bottom: 4%; */
    padding-bottom: 2em;
    display: flex;
    align-items: center;
}

.player__poster__cover__teams > img {
    position: absolute;
    /* width: 50% */
}

.player__poster__cover__teams > img:first-child {
    left: 13%;
    top: -6%;
    /* -webkit-transform: perspective(460px) rotateX(20deg) rotateY(45deg) rotateZ(0); */
    /* transform: perspective(438px) rotateX(20deg) rotateY(45deg) rotateZ(0); */
    max-width: 9.5em;
    /* transform-origin: center center 0; */
}

.player__poster__cover__teams > img:last-child {
    right: 13%;
    top: -6%;
    /* -webkit-transform: perspective(460px) rotateX(20deg) rotateY(-45deg) rotateZ(0); */
    /* transform: perspective(460px) rotateX(20deg) rotateY(-45deg) rotateZ(0); */
    /* transform-origin: center center 0; */
    /* max-width: 140px; */
    max-width: 9.5em;
}

@media (max-width:992px){

    .player__poster__cover__teams > img:first-child {
        left: 13%;
        top: 0%;
        max-width: 6.5em;
    }
    
    .player__poster__cover__teams > img:last-child {
        right: 13%;
        top: 0%;
        max-width: 6.5em;
    }
}

@media (max-width:768px){

    .player__poster__cover__teams > img:first-child {
        left: 4%;
        top: -2%;
        max-width: 5.5em;
    }
    
    .player__poster__cover__teams > img:last-child {
        right: 4%;
        top: -2%;
        max-width: 5.5em;
    }
}

@media (max-width:576px) {
    .player__poster__state > p {
        font-size: 0.85em
    }

    .player__poster__cover__teams > img:first-child {
        left: 4%;
        top: -6%;
        max-width: 5.5em;
    }
    
    .player__poster__cover__teams > img:last-child {
        right: 4%;
        top: -6%;
        max-width: 5.5em;
    }
}

@media (max-width:414px){

    .player__poster__cover__teams > img:first-child {
        left: 4%;
        top: -6%;
        max-width: 5.5em;
    }
    
    .player__poster__cover__teams > img:last-child {
        right: 4%;
        top: -6%;
        max-width: 5.5em;
    }
}

@media (max-width:414px){
    .player__poster__cover__score img {
        max-height: 3.5em;
        max-width: 3.5em;
    }

    .player__poster__cover__score_boxing img {
        max-height: 3.5em;
        max-width: 2.5em;
    }
}

@media (max-width:320px){
    
    
    .player__poster__cover__teams > img:first-child {
        left: 0%;
        top: -4%;
        max-width: 4.5em;
    }
    
    .player__poster__cover__teams > img:last-child {
        right: 0%;
        top: -4%;
        max-width: 4.5em;
    }
}

.player__poster .thumb {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: -1
}

.player__poster .thumb > img {
    opacity: 0.25;
    padding: 2.5rem
}

.player__poster > img {
    cursor: pointer;
    opacity: 0.5;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-in, transform 0.1s ease-in
}

.player__poster > img:hover {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%), scale(0.9);
    transform: translate(-50%, -50%), scale(0.9)
}

.player__video,
.player iframe {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

/* .player__scores { */
    /* display: flex; */
    /* align-items: center;
    width: 100%;
    margin: 0 auto;
    text-align: center */
/* } */

.player__poster__cover__score {
    width: 100%;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    padding-bottom: 2em;
}

.player__poster__cover__score_boxing {
    width: 100%;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    padding-bottom: 2em;
}

.player__poster__cover__score img {
    max-height: 9.5em;
    max-width: 9.5em;
    width: 100%;
    height: 100%;
    margin: auto;
}

.player__poster__cover__score_boxing img {
    max-height: 9.5em;
    max-width: 7.5em;
    width: 100%;
    height: 100%;
    margin: auto;
}

.player__scores {
    display: flex;
    align-items: center;
}

.player__scores > span {
    font-size: 4em;
    margin: 0 3rem;
    color: #0f1324;
}

@media (max-width:768px) {
    .player__scores > span {
        font-size: 2.5em;
        margin: 0 2rem
    }
}

@media (max-width:576px) {
    .player__poster__cover__score img {
        max-height: 4.5em;
        max-width: 4.5em;
    }

    .player__poster__cover__score_boxing img {
        max-height: 4.5em;
        max-width: 3.5em;
    }

    .player__scores > span {
        font-size: 2em;
        margin: 0 1rem
    }
}

.player__scores > span.period {
    font-size: 1em
}

.subplayer {
    padding: 1.5em 0;
    color: #f7f7f7;
}

@media (max-width:576px) {
    .subplayer .img-channel {
        display: none
    }
}

@media (max-width:576px) {
    .subplayer .img-channel.live {
        display: block
    }
}

.stats-holder {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
	
}

.stats-holder.football{
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.stats-holder.nba{
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.stats-holder.nhl{
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.stats-holder.nfl{
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.stats-holder.ncaaf{
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.stats-holder.mlb{
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.stats-holder:before {
    content: '';
    position: absolute;
    top: 2.5em;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.05;
    filter: invert(1);				  
	
}

.stats-teams {
    width: 100%;
    margin: 0 auto 2em;
    text-align: center;
    font-size: 1.2em;
    font-weight: 700;
}

.stats-teams thead tr {
    height: 4em;
}

.stats-teams tbody tr {
    height: 3em;
}

.stats-teams tbody tr td.stats-teams__team:before {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #0c1117;
    width: 100%;
    max-width: 20px;
    height: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: linear-gradient(-45deg, rgba(255, 255, 255, 0.5), #121627, rgba(255, 255, 255, 0.1));
    background-size: 400% 400%;
    -webkit-animation: Gradient 2s ease infinite;
    -moz-animation: Gradient 2s ease infinite;
    animation: Gradient 2s ease infinite;
}

.stats-teams__team {
    width: 20%;
    position: relative;
	color: #0f1324;			   
}

.stats-teams__label {
    width: 60%;
    font-weight: 400;
    color: #0f1324;
}

.header-club.football {
    background-image: linear-gradient(rgba(28, 36, 44, 0.2),rgba(28, 36, 44, 0.9)), url('/images/resources/football-team-page-background.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.header-club.nba {
    background-image: linear-gradient(rgba(28, 36, 44, 0.2),rgba(28, 36, 44, 0.9)), url('/images/resources/nba-team-page-background.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.header-club.nfl {
    background-image: linear-gradient(rgba(28, 36, 44, 0.2),rgba(28, 36, 44, 0.9)), url('/images/resources/nfl-team-page-background.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.header-club.ncaaf {
    background-image: linear-gradient(rgba(28, 36, 44, 0.2),rgba(28, 36, 44, 0.9)), url('/images/resources/ncaaf-team-page-background.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.header-club.mlb {
    background-image: linear-gradient(rgba(28, 36, 44, 0.2),rgba(28, 36, 44, 0.9)), url('/images/resources/mlb-team-page-background.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.header-club.nhl {
    background-image: linear-gradient(rgba(28, 36, 44, 0.2),rgba(28, 36, 44, 0.9)), url('/images/resources/nhl-team-page-background.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.header-club.ufc {
    background-image: linear-gradient(rgba(28, 36, 44, 0.2),rgba(28, 36, 44, 0.9)), url('/images/resources/ufc-team-page-background.jpg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.header-club.boxing {
    background-image: linear-gradient(rgba(28, 36, 44, 0.2),rgba(28, 36, 44, 0.9)), url('/images/resources/boxing-team-page-background.jpg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.header-club:before {
    content: '';
    position: absolute;
    z-index: -2;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #0f151c
}

.header-club .club {
    padding: 2em 0;
    text-align: center
}

.header-club .club__logo {
    width: 100%;
    max-width: 12em;
    margin-bottom: 1em
}

.header-club .club__stats ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none
}

/******************* races *********************/

.game-live .game-live__race {
    width: 80%;
    display: flex;
    align-items: center;
}

.game-live .game-live__main_races {
    display: flex;
    align-items: center;
    padding: 1em;
    background-color: #efefef;
}

.game-live .game-live__competition__holder {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 25%;
}

.game-live .game-live__competition__holder>a {
    color: #121627;
    display: block;
    text-align: center;
    width: 100%;
}

.game-live .game-live__competition__holder>a img {
    width: 100%;
    max-width: 130px;
    margin-bottom: 0.5em;
}

.game-live .game-live__race_event__holder {
    display: block;
    align-items: center;
    width: 75%;
    text-align: center;
}

.game-live .game-live__race_event__holder .game-live__event_location {
    font-size: 1em;
    margin: 0 0 .1em 0;
    color: #0f1324;
    width: 100%;
    text-align: center;
}

.game-live .game-live__race_event__holder .game-live__event_name{
    font-size: 1.2em;
    margin: 0 0 .1em 0;
    color: #0f1324;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    word-spacing: 1px;
}

.game-live .game-live__race_event__holder .game-live__event_track {
    font-size: 0.8em;
    margin: 0 0 .2em 0;
    color: #0f1324;
    width: 100%;
    text-align: center;
    letter-spacing: 0px;
    word-spacing: 0px;
}

.game-live .game-live__race_event__holder .live-badge {
    display: inline-flex;
    align-items: center;
    padding: .35em .5em;
    margin: 0 25px;
    border: 1px solid red;
    border-radius: .25rem;
    color: red;
}

.game-upcoming__races .game-upcoming__race {
    width: 80%;
    display: flex;
    align-items: center;
}

.game-upcoming__races .game-upcoming__competition__holder {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 25%;
}

.game-upcoming__races .game-upcoming__competition__holder>a {
    color: #121627;
    display: block;
    text-align: center;
    width: 100%;
}

.game-upcoming__races .game-upcoming__competition__holder>a img {
    width: 100%;
    max-width: 130px;
    margin-bottom: 0.5em;
}

.game-upcoming__races .game-upcoming__event__holder {
    display: block;
    align-items: center;
    width: 75%;
    text-align: center;
}

.game-upcoming__races .game-upcoming__event__holder .game-upcoming__event_location {
    font-size: 1em;
    margin: 0 0 .1em 0;
    color: #0f1324;
    width: 100%;
    text-align: center;
}

.game-upcoming__races .game-upcoming__event__holder .game-upcoming__event_name {
    font-size: 1.2em;
    margin: 0 0 .1em 0;
    color: #0f1324;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    word-spacing: 1px;
}

.game-upcoming__races .game-upcoming__event__holder .game-upcoming__event_track {
    font-size: 0.8em;
    margin: 0 0 .2em 0;
    color: #0f1324;
    width: 100%;
    text-align: center;
    letter-spacing: 0px;
    word-spacing: 0px;
}

.game-upcoming__races .game-upcoming__event__holder .live-badge {
    display: inline-flex;
    align-items: center;
    padding: .35em .5em;
    margin: 0 25px;
    border: 1px solid red;
    border-radius: .25rem;
    color: red;
}

.game-upcoming__races {
    display: flex;
    align-items: center;
    padding: 1em;
    margin-top: .5em;
    background-color: #efefef;
}

.game_upcoming__event_date_time {
    display: flex;
    width: 100%;
    text-align: center;
    margin: 0 0 .1em 0;
}

.game_upcoming__event_date_time .game-upcoming__teams__date{
    color: #76af2a;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    font-size: 1em;
}

.game-upcoming__races .game-upcoming__cta {
    width: 20%;
    color:#f5f5f5;
    text-align: right;
}

.player__scores__races {
    align-items: center;
    margin-top: 2em;
    width: 100%;
    margin-left: 2em;
}

.racing_club__stats {
    color:black;
}

.text-track-info {
    font-size: .925em;
    /* color: rgba(255,255,255,.7); */
}

.racing_ul {
    padding-left: 0px;
    margin-left: 0;
    list-style-type: none;
}

.player__event_location {
    font-size: 2em;
    margin: 0 0 .1em 0;
    color: #0f1324;
    width: 100%;
    text-align: center;
}

.player__event_race_name {
    font-size: 2em;
    margin: 0 0 .1em 0;
    color: #0f1324;
    width: 100%;
}

.player__poster__cover__race {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    padding-bottom: 1em;
}

.player__poster__cover__race img {
    max-height: 8.5em;
    max-width: 8.5em;
    width: 100%;
    height: 100%;
    margin: auto;
}

.driver_element {
    display: flex;
    align-items: center;
    padding: 1em;
    margin-top: .5em;
    background-color: #efefef;
}

.driver_row {
    width: 100%;
    display: flex;
    align-items: center;
    color: black;
}

.drivers_leaderboard_header {
    display: flex;
    align-items: center;
    padding: 1em;
    margin-top: .5em;
    background-color: #efefef;
}

.drivers_header {
    width: 100%;
    display: flex;
    align-items: center;
    color:#0f1324;
    font-weight: bold;
}

.driver_data {
    width: 100%;
    align-items: center;
    display: flex;
    padding-right: 0.7em;
}

.driver_img {
    max-width: 150px;
}

.driver_pos {
    padding-right: 0.7em;
    width: 5%;
    text-align: center;
}

.driver_started {
    text-align: center;
    padding-right: .7em;
    width: 10%;
}

.driver_laps_led {
    text-align: center;
    padding-right: 0.7em;
    width: 10%;
}

.driver_score {
    text-align: center;
    width: 10%;
    padding-right: 0.7em;
}

.driver_manufacturer {
    width: 25%;
    text-align:center;
    padding-right: 0.7em;
}

.driver_pits_taken {
    text-align:center;
    padding-right: 0.7em;
    width: 10%;
}

.driver_laps {
    text-align:center;
    padding-right: 0.7em;
    width: 10%;
}

.driver_name {
    font-weight: bold;
    font-size: 18px;
}

.driver_time {
    text-align:center;
    width: 25%;
    padding-right: 0.7em;
}

.driver_status {
    width: 15%;
    text-align:center;
    padding-right: 0.7em;
}

@media screen and (max-width: 768px)
{
    .drivers_list{
        padding-left: 0px;
    }
    .driver_laps_led {
        width: 25%;
    }

    .driver_pos {
        width: 12%;
    }
    
    .driver_score {
        display:none;
    }
    
    .driver_pits_taken {
        display:none;
    }
    
    .driver_time {
        display:none;
    }

    .header-index.racing{
        height: 300px;
    }
    .header-index.racing-f1{
        height: 300px;
    }
    .header-index.racing-nascar{
        height: 300px;
    }
    .header-index.racing-motogp{
        height: 300px;
    }

    .game-upcoming__races .game-upcoming__cta {
        width: 20%;
    }

    .games-live .game-live__main_races .game-live__cta {
        width: 20%;
    }

    .game-live .game-live__event__holder .game-live__event_location {
        display:none;
    }

    .player__scores__fights > span {
        font-size: 2.5em;
        margin: 0 2rem
    }

    .player__poster__cover__race img {
        max-height: 10.5em;
        max-width: 10.5em;
    }

    .player__event_type {
        font-size: 2em;
        
    }

    .player__event_name{
        font-size: 2em;
    }


}

@media screen and (max-width: 576px)
{
    .drivers_list{
        padding-left: 0px;
    }
    .driver_laps_led {
        display:none;
    }

    .driver_pos {
        width: 12%;
    }
    
    .driver_score {
        display:none;
    }
    .driver_started {
        display:none;
    }
    .driver_manufacturer {
        display:none;
    }
    
    .driver_pits_taken {
        display:none;
    }
    
    .driver_laps {
        display:none;
    }
    
    .driver_time {
        display:none;
    }

    .header-index.racing{
        height: 200px;
    }
    .header-index.racing-f1{
        height: 200px;
    }
    .header-index.racing-nascar{
        height: 200px;
    }
    .header-index.racing-motogp{
        height: 200px;
    }
    .games-live .game-live__main_races {
        flex-direction: column;
    }

    .game-upcoming__races {
        flex-direction: column;
    }
    .game-upcoming__races .game-upcoming__cta>div .btn {
        width: 100%;
    }

    .games-live .game-live__main_races .game-live__cta>div .btn {
        width: 100%;
    }

    .game-upcoming__races .game-upcoming__cta {
        width: 100%;
    }

    .fight-card_fight {
        flex-direction: column;
    }

    .fight-card_fight .fight-card_fight__cta {
        width: 100%;
    }

    .fight-card_fight .fight-card_fight__cta >div .btn {
        width: 100%;
    }

    .fight-card_fight__event__holder .fight-card_fight__event__date {
        display:none;
    }

    .games-live .game-live__main_races .game-live__cta {
        width: 100%;
    }

    .game-live .game-live__event__holder .game-live__event_location {
        display:none;
    }

    .game-upcoming__races .game-upcoming__event__holder .game-upcoming__event_location {
        display:none;
    }

    .game-live .game-live__event__holder .live-badge {
        margin: 0 25px .5em;
    }

    .player__scores__fights > span {
        font-size: 2em;
        margin: 0 1rem
    }

    .player__poster__cover__race img {
        max-height: 7.5em;
        max-width: 7.5em;
    }

    .player__event_type {
        font-size: 2em;
        
    }

    .player__event_name{
        font-size: 1.7em;
        
    }

    .fight-card_fight__event_stats {
        font-size: 1em;
    }

    .fight-card_fight__event_stats_round span.fight-card_fight__event_stats_val {
        margin: 0 50%;
    }

}
@media only screen and (max-width: 480px)
{
    .hero-text-box {
        font-size: 90%;
    }
}

@media screen and (max-width: 430px)
{

    
    .drivers_list{
        padding-left: 0px;
    }
    .driver_laps_led {
        display:none;
    }

    .driver_pos {
        width: 12%;
    }
    
    .driver_score {
        display:none;
    }

    .driver_started {
        display:none;
    }

    .driver_manufacturer {
        display:none;
    }

    .driver_status {
        display:none;
    }
    
    .driver_pits_taken {
        display:none;
    }
    
    .driver_laps {
        display:none;
    }
    
    .driver_time {
        display:none;
    }
    
    .player__poster__cover__race img {
        max-height: 6.5em;
        max-width: 6.5em;
    }

    .player__event_type {
        font-size: 1.5em;
        
    }

    .player__event_name{
        font-size: 1.5em;
        
    }

    .fight-card_fight__event__holder .fight-card_fight__event_name {
        font-size:1em;
    }

    .fight-card_fight__event__holder .fight-card_fight__event_main_name {
        display: none;
    }

    .fight-card_fight__event__holder .fight-card_fight__event_weightclass {
        font-size:0.8em;
    }

    .fight-card_fight__event_stats {
        font-size: 0.8em;
    }

}
/******************* end races *********************/

/******************* fighter *********************/

.header-club.ufc .club__logo {
    width: 100%;
    max-width: 20em;
    margin-bottom: 1em;
}

.header-club.boxing .club__logo {
    width: 100%;
    max-width: 14em;
    margin-bottom: 1em;
}

.header-club.ufc .title-large {
    color: #f5f5f5;
    font-size: 3em;
    margin: .1em 0;
    /* margin-bottom: .5em; */
}

.header-club.boxing .title-large {
    color: #f5f5f5;
    font-size: 3em;
    margin: .1em 0;
    /* margin-bottom: .5em; */
}

.header-club.ufc .text-club-info {
    font-size: 1.3em;
    color: rgba(255,255,255,.7);
}

.fight-card-holder.boxing {
    max-width: 800px;
    text-align: center;
    margin: auto;
}


.game-live .game-live__fighters {
    width: 80%;
    display: flex;
    align-items: center;
}

.game-live .game-live__main_fights {
    display: flex;
    align-items: center;
    padding: 1em;
    background-color: #efefef;
}

.game-live .game-live__fighter__holder {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 25%;
}

.game-live .game-live__fighter__holder>a {
    color: #121627;
    display: block;
    text-align: center;
    width: 100%;
}

.game-live .game-live__fighter__holder>a img {
    width: 100%;
    max-width: 130px;
    margin-bottom: 0.5em;
}

.game-live .game-live__event__holder {
    display: block;
    align-items: center;
    width: 50%;
    text-align: center;
}

.game-live .game-live__event__holder .game-live__event_type {
    font-size: 1em;
    margin: 0 0 .1em 0;
    color: #0f1324;
    width: 100%;
    text-align: center;
}

.game-live .game-live__event__holder .game-live__event_main_card {
    font-size: 1.2em;
    margin: 0 0 .1em 0;
    color: #0f1324;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    word-spacing: 1px;
}

.game-live .game-live__event__holder .game-live__event_city {
    font-size: 0.8em;
    margin: 0 0 .2em 0;
    color: #0f1324;
    width: 100%;
    text-align: center;
    letter-spacing: 0px;
    word-spacing: 0px;
}

.game-live .game-live__event__holder .live-badge {
    display: inline-flex;
    align-items: center;
    padding: .35em .5em;
    margin: 0 25px;
    border: 1px solid red;
    border-radius: .25rem;
    color: red;
}

.game-upcoming__fights .game-upcoming__fighters {
    width: 80%;
    display: flex;
    align-items: center;
}

.game-upcoming__fights .game-upcoming__fighter__holder {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 25%;
}

.game-upcoming__fights .game-upcoming__fighter__holder>a {
    color: #121627;
    display: block;
    text-align: center;
    width: 100%;
}

.game-upcoming__fights .game-upcoming__fighter__holder>a img {
    width: 100%;
    max-width: 130px;
    margin-bottom: 0.5em;
}

.game-upcoming__fights .game-upcoming__event__holder {
    display: block;
    align-items: center;
    width: 50%;
    text-align: center;
}

.game-upcoming__fights .game-upcoming__event__holder .game-upcoming__event_type {
    font-size: 1em;
    margin: 0 0 .1em 0;
    color: #0f1324;
    width: 100%;
    text-align: center;
}

.game-upcoming__fights .game-upcoming__event__holder .game-upcoming__event_main_card {
    font-size: 1.2em;
    margin: 0 0 .1em 0;
    color: #0f1324;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    word-spacing: 1px;
}

.game-upcoming__fights .game-upcoming__event__holder .game-upcoming__event_city {
    font-size: 0.8em;
    margin: 0 0 .2em 0;
    color: #0f1324;
    width: 100%;
    text-align: center;
    letter-spacing: 0px;
    word-spacing: 0px;
}

.game-upcoming__fights .game-upcoming__event__holder .live-badge {
    display: inline-flex;
    align-items: center;
    padding: .35em .5em;
    margin: 0 25px;
    border: 1px solid red;
    border-radius: .25rem;
    color: red;
}

.game-upcoming__fights {
    display: flex;
    align-items: center;
    padding: 1em;
    margin-top: .5em;
    background-color: #efefef;
}

.game_upcoming__event_date_time {
    display: flex;
    width: 100%;
    text-align: center;
    margin: 0 0 .1em 0;
}

.game_upcoming__event_date_time .game-upcoming__teams__date{
    color: #76af2a;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    font-size: 1em;
}

.game-upcoming__fights .game-upcoming__cta {
    width: 20%;
    color:#f5f5f5;
    text-align: right;
}

.player__scores__fights {
    align-items: center;
    margin-top: 3em;
}

.player__event_type {
    font-size: 2em;
    margin: 0 0 .1em 0;
    color: #0f1324;
    width: 100%;
    text-align: center;
}

.player__event_name {
    font-size: 2em;
    margin: 0 0 .1em 0;
    color: #0f1324;
    width: 100%;
    text-align: center;
}

.player__poster__cover__score img {
    max-height: 14.5em;
    max-width: 14.5em;
    width: 100%;
    height: 100%;
    margin: auto;
}

.player__poster__cover__score_boxing img {
    max-height: 14.5em;
    max-width: 11.5em;
    width: 100%;
    height: 100%;
    margin: auto;
}

.fight-card_fight .fight-card_fight__fighters {
    width: 80%;
    display: flex;
    align-items: center;
}

.fight-card_fight .fight-card_fight__fighter__holder {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 25%;
}

.fight-card_fight .fight-card_fight__fighter__holder.boxing {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 6%;
}

.fight-card_fight .fight-card_fight__fighter__holder>a {
    color: #121627;
    display: block;
    text-align: center;
    width: 100%;
}

.fight-card_fight__fighters .fight-card_fight__fighter__holder>a img {
    width: 100%;
    max-width: 130px;
    margin-bottom: 0.5em;
}

.fight-card_fight {
    display: flex;
    align-items: center;
    padding: 1em;
    margin-top: .5em;
    background-color: #efefef;
}

.fight-card_fight .fight-card_fight__cta {
    width: 20%;
    color: #0f1324;
    text-align: right;
}

.fight-card_fight>div:last-child {
    align-items: flex-end;
}

.fight-card_fight .fight-card_fight__event__holder{
    display: block;
    align-items: center;
    width: 50%;
    text-align: center;
}

.fight-card_fight .fight-card_fight__event__holder.boxing {
    display: block;
    align-items: center;
    width: 94%;
    text-align: center;
}

.fight-card_fight .fight-card_fight__fighter__holder.boxing {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 6%;
}

.fight-card_fight__event__holder .fight-card_fight__event__date{
    font-size: 0.8em;
    margin: 0 0 .2em 0;
    color: #0f1324;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0px;
    word-spacing: 0px;
}

.fight-card_fight__event__holder .fight-card_fight__event_name {
    font-size: 1.2em;
    margin: 0 0 .1em 0;
    color: #0f1324;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    word-spacing: 1px;
}

.fight-card_fight__event__holder .fight-card_fight__event_main_name {
    font-size: 1em;
    margin: 0 0 .1em 0;
    color: #0f1324;
    width: 100%;
    text-align: center;
    word-spacing: 1px;
}

.fight-card_fight__event__holder .fight-card_fight__event_weightclass{
    color: #0f1324;
    text-align: center;
    width: 100%;
    font-size: 1em;
}

.fight-card_fight__event_stats {
    display: flex;
    color:#0f1324;
    width: 100%;
    text-align: center;
}

.fight-card_fight__event_stats_method {
    width: 30%;
    display: block;
    text-align: right;
}

.fight-card_fight__event_stats_decision {
    width: 20%;
    display: block;
    text-align: left;
    margin-left: 1em;
}

.fight-card_fight__event_stats_location {
    width: 100%;
    display: block;
    text-align: right;
}

.fight-card_fight__event_stats_time {
    width: 30%;
    display: block;
    text-align: center;
}

.fight-card_fight__event_stats_round {
    width: 30%;
    display: block;
    text-align: center;
}


span.fight-card_fight__event_stats_val {
    width: 100%;
    margin: 0 auto;
}

span.fight-card_fight__event_stats_header {
    width: 100%;
    margin: 0 auto;
    color: #8a8989;
}

ul.fight-card_list {
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 768px)
{
    .header-index.ufc{
        height: 300px;
    }

    .game-upcoming__fights .game-upcoming__cta {
        width: 20%;
    }

    .games-live .game-live__main_fights .game-live__cta {
        width: 20%;
    }

    .game-live .game-live__event__holder .game-live__event_city {
        display:none;
    }

    .player__scores__fights > span {
        font-size: 2.5em;
        margin: 0 2rem
    }

    .player__poster__cover__score img {
        max-height: 10.5em;
        max-width: 10.5em;
    }

    .player__poster__cover__score_boxing img {
        max-height: 10.5em;
        max-width: 8.5em;
    }

    .player__event_type {
        font-size: 2em;
        
    }

    .player__event_name{
        font-size: 2em;
    }


}

@media screen and (max-width: 576px)
{
    .header-index.ufc{
        height: 200px;
    }
    .games-live .game-live__main_fights {
        flex-direction: column;
    }

    .game-upcoming__fights {
        flex-direction: column;
    }
    .game-upcoming__fights .game-upcoming__cta>div .btn {
        width: 100%;
    }

    .games-live .game-live__main_fights .game-live__cta>div .btn {
        width: 100%;
    }

    .game-upcoming__fights .game-upcoming__cta {
        width: 100%;
    }

    .fight-card_fight {
        flex-direction: column;
    }

    .fight-card_fight .fight-card_fight__cta {
        width: 100%;
    }

    .fight-card_fight .fight-card_fight__cta >div .btn {
        width: 100%;
    }

    .fight-card_fight__event__holder .fight-card_fight__event__date {
        display:none;
    }

    .games-live .game-live__main_fights .game-live__cta {
        width: 100%;
    }

    .game-live .game-live__event__holder .game-live__event_city {
        display:none;
    }

    .game-upcoming__fights .game-upcoming__event__holder .game-upcoming__event_city {
        display:none;
    }

    .game-live .game-live__event__holder .live-badge {
        margin: 0 25px .5em;
    }

    .player__scores__fights > span {
        font-size: 2em;
        margin: 0 1rem
    }

    .player__poster__cover__score img {
        max-height: 7.5em;
        max-width: 7.5em;
    }
    
    .player__poster__cover__score_boxing img {
        max-height: 7.5em;
        max-width: 6em;
    }
    
    .player__event_type {
        font-size: 2em;
        
    }

    .player__event_name{
        font-size: 1.7em;
        
    }

    .fight-card_fight__event_stats {
        font-size: 1em;
    }

    .fight-card_fight__event_stats_round span.fight-card_fight__event_stats_val {
        margin: 0 50%;
    }

}

@media screen and (max-width: 430px)
{

    .player__poster__cover__score img {
        max-height: 6.5em;
        max-width: 6.5em;
    }

    .player__poster__cover__score_boxing img {
        max-height: 6.5em;
        max-width: 5em;
    }

    .player__event_type {
        font-size: 1.5em;
        
    }

    .player__event_name{
        font-size: 1.5em;
        
    }

    .fight-card_fight__event__holder .fight-card_fight__event_name {
        font-size:1em;
    }

    .fight-card_fight__event__holder .fight-card_fight__event_main_name {
        display: none;
    }

    .fight-card_fight__event__holder .fight-card_fight__event_weightclass {
        font-size:0.8em;
    }

    .fight-card_fight__event_stats {
        font-size: 0.8em;
    }

}
/******************* end fighter *********************/

@media (max-width:576px) {
    .header-club .club__stats ul {
        flex-direction: column
    }
}

.header-club .club__stats ul li {
    padding: 0.25em 1em
}

.features__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-around
}

@media (max-width:768px) {
    .features__list {
        align-items: flex-start
    }
}

.features__list li {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0.5em
}

@media (max-width:768px) {
    .features__list li {
        flex-direction: column;
        text-align: center
    }
}

.features__list li > div {
    margin-left: 1em
}

@media (max-width:768px) {
    .features__list li > div {
        margin-left: 0
    }
}

.features__list li h1 {
    color: #121627;
    text-transform: uppercase;
    margin-bottom: 0.25em
}

@media (max-width:768px) {
    .features__list li h1 {
        font-size: 0.8em
    }
}

.features__list li h2 {
    margin-bottom: 0
}

@media (max-width:768px) {
    .features__list li h2 {
        display: none
    }
}

.features__list li img {
    opacity: 0.7;
}

@media (max-width:768px) {
    .features__list li img {
        min-height: 60px
    }
}

@media (max-width:768px) {
    .device-features__content {
        text-align: center;
        margin-top: 2em
    }
}

.device-features {background-color: #121627;}

.device-features__brands {
    margin-bottom: 0.5em
}

.device-features__brands img {
    margin: 1em;
    opacity: 0.5
}

.banner-unlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin: 2em auto 0;
    padding: 1em;
    background-color: #efefef;
    border-radius: 5px
}

@media (max-width:576px) {
    .banner-unlock {
        flex-direction: column
    }

    .banner-unlock .btn {
        margin-top: 1em
    }
}

.countdown {
    text-align: center;
    margin-top: 0.5em;
    padding: 0.5em 1em 0.25em;
    background-color: rgba(12, 17, 23, 0.8)
}

@media (max-width:768px) {
    .countdown {
        padding: 0.5em 0.25em 0.25em
    }
}

.countdown__timer {
    font-size: 3em;
    line-height: 1;
    letter-spacing: 5px;
    font-weight: 700
}

@media (max-width:768px) {
    .countdown__timer {
        font-size: 2em
    }
}

.countdown__formats {
    display: flex;
    justify-content: space-around
}

.countdown__formats > span {
    font-size: 0.9em;
    color: rgba(247, 251, 255, 0.6);
    margin: 0 25px
}

@media (max-width:768px) {
    .countdown__formats > span {
        font-size: 0.6em;
        margin: 0 20px
    }
}

.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(12, 17, 23, 0.85);
    z-index: 999
}

.modal__close {
    position: absolute;
    top: 0.5em;
    right: 1em;
    cursor: pointer
}

.modal__close i {
    font-size: 1.5em;
    transition: transform 0.1s ease-in-out;
    color: rgba(255, 255, 255, 0.5)
}

.modal__close:hover i,
.modal__close:focus i {
    -webkit-transform: scale(0.9);
    transform: scale(0.9)
}

.modal__header.football {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(rgba(12, 17, 23, 0.75), rgba(12, 17, 23, 0.75)), url('/images/resources/football-modal-header.jpg') no-repeat center center/cover;
    /* background: #121427; */
    border-radius: 5px 5px 0 0;
    padding: 1.75em 1em
}

.modal__header.nba {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(rgba(12, 17, 23, 0.75), rgba(12, 17, 23, 0.75)), url('/images/resources/nba-modal-header.jpg') no-repeat center center/cover;
    /* background: #121427; */
    border-radius: 5px 5px 0 0;
    padding: 1.75em 1em
}

.modal__header.ncaaf {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(rgba(12, 17, 23, 0.75), rgba(12, 17, 23, 0.75)), url('/images/resources/ncaaf-modal-header.jpg') no-repeat center center/cover;
    /* background: #121427; */
    border-radius: 5px 5px 0 0;
    padding: 1.75em 1em
}

.modal__header.nfl {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(rgba(12, 17, 23, 0.75), rgba(12, 17, 23, 0.75)), url('/images/resources/nfl-modal-header.jpg') no-repeat center center/cover;
    /* background: #121427; */
    border-radius: 5px 5px 0 0;
    padding: 1.75em 1em
}

.modal__header.mlb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(rgba(12, 17, 23, 0.75), rgba(12, 17, 23, 0.75)), url('/images/resources/mlb-modal-header.jpg') no-repeat center center/cover;
    /* background: #121427; */
    border-radius: 5px 5px 0 0;
    padding: 1.75em 1em
}

.modal__header.nhl {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(rgba(12, 17, 23, 0.75), rgba(12, 17, 23, 0.75)), url('/images/resources/nhl-modal-header.jpg') no-repeat center center/cover;
    /* background: #121427; */
    border-radius: 5px 5px 0 0;
    padding: 1.75em 1em
}

.modal__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 800px;
    padding: 0 15px
}

.modal__inner {
    opacity: 0;
    transform: scale(0.3);
    transition: opacity 0.3s ease-in, transform 0.2s ease-in;
    -webkit-box-shadow: 0 5px 25px 0 rgba(12, 17, 23, 0.35);
    -moz-box-shadow: 0 5px 25px 0 rgba(12, 17, 23, 0.35);
    box-shadow: 0 5px 25px 0 rgba(12, 17, 23, 0.35);
    background: #121627;
    border-radius: 5px
}

.modal__inner.active {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.modal__content {
    text-align: center;
    color: #121627;
    padding: 3em 1em;
    background: #f5f5f5
}

.modal__content h1 {
    font-weight: 700;
    font-size: 2em
}

.modal__content p {
    font-size: 1.1em
}

@media (max-width:576px) {
    .modal__content h1 {
        font-size: 1.4em
    }

    .modal__content p {
        font-size: 0.8em
    }
}

.modal__footer {
    padding: 1em;
    border-radius: 0 0 5px 5px;
    background: #efefef;
    text-align: center
}

.modal__footer .features__list li {
    flex-direction: column
}

.modal__footer .features__list li h1 {
    color: #0c1117;
    font-weight: 700;
    font-size: 0.7em
}

.modal__footer .features__list li img {
    opacity: 1;
    min-height: 50px
}

.footer {
    border-top: 1px solid rgba(247, 251, 255, 0.1);
    padding: 1.6em 0;
        background-color: #121627;
}

.footer .copyright {
    font-size: 0.8em;
    color: rgba(247, 251, 255, 0.6)
}

.footer__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center
}

@media (max-width:576px) {
    .footer__wrapper {
        flex-direction: column
    }
}

.footer__links > a {
    display: inline-block;
    color: rgba(247, 251, 255, 0.6);
    text-decoration: none;
    font-size: 0.8em;
    margin: 0 1.2em
}

@media (max-width:576px) {
    .footer__links > a {
        margin: 2em 1.2em
    }
}

.preloader-overlay {
    opacity: 0;
    z-index: 99999;
    transition: 0.2s 0.2s;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out
}

.preloader-overlay.active {
    display: block;
    opacity: 1
}

.preloader-overlay .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation: logo-loader 1.4s infinite ease-in-out both;
    animation: logo-loader 1.4s infinite ease-in-out both
}

.preloader {
    margin: 0 auto 0;
    width: 60px;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.preloader--relative {
    position: relative;
    top: unset;
    left: unset;
    -webkit-transform: unset;
    transform: unset
}

.preloader > div {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both
}

.preloader .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s
}

.preloader .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s
}



@-webkit-keyframes pulse {
    0% {
        opacity: 1
    }

    50% {
        opacity: 0.1
    }

    100% {
        opacity: 1
    }
}

@-moz-keyframes pulse {
    0% {
        opacity: 1
    }

    50% {
        opacity: 0.1
    }

    100% {
        opacity: 1
    }
}

@keyframes pulse {
    0% {
        opacity: 1
    }

    50% {
        opacity: 0.1
    }

    100% {
        opacity: 1
    }
}

@-webkit-keyframes logo-loader {
    0% {
        opacity: 1
    }

    50% {
        opacity: 0.6
    }

    100% {
        opacity: 1
    }
}

@-moz-keyframes logo-loader {
    0% {
        opacity: 1
    }

    50% {
        opacity: 0.6
    }

    100% {
        opacity: 1
    }
}

@keyframes logo-loader {
    0% {
        opacity: 1
    }

    50% {
        opacity: 0.6
    }

    100% {
        opacity: 1
    }
}

@-webkit-keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-moz-keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes Gradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@-moz-keyframes Gradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@keyframes Gradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

body {}

body:before,
body:after {
    opacity: 0.15
}

@media (max-width:576px) {

    body:before,
    body:after {
        background-size: 40%
    }
}

.tint {padding: 0 0 4em 0}						  

.stats-holder.football:before {
    background-image: url('/images/resources/football-field.svg');
    opacity: 0.05
}

.stats-holder.nba:before {
    background-image: url('/images/resources/nba-field.svg');
    opacity: 0.05
}

.stats-holder.nhl:before {
    background-image: url('/images/resources/nhl-field.svg');
    opacity: 0.05
}

.stats-holder.mlb:before {
    background-image: url('/images/resources/mlb-field.svg');
    opacity: 0.05
}

.stats-holder.nfl:before {
    background-image: url('/images/resources/nfl-field.svg');
    opacity: 0.05
}


.stats-holder.ncaaf:before {
    background-image: url('/images/resources/nfl-field.svg');
    opacity: 0.05
}

.txt_l_player a {
    font-size: 95%;
    color: #121627;
    line-height: 1.5;
    white-space: unset;
    margin-bottom: 7px;
    padding: 0.6rem
}

.txt_l_player a span {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 1em;
    position: relative;
    top: -1px;
    background-color: #daa300;
    padding: 0.1em 0.25em;
    border-radius: 2px
}

.txt_l_player a:hover {
    text-decoration: none;
    font-size: 96%
}

.legal-pages {color: #121627;}							 

/* Redirectless form */

.playercontentfinal {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

#playerCta-wrapper {
    display: none;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99999;
    overflow: auto;
    left:0;
}

#playerCta.download {
    width: 23em;
    top: 15%;
    left: 50%;
    display: block;
}

#playerCta {
    width: 50em;
    /* color: #222; */
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 89%, rgba(255, 255, 255, 0) 89%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

#playerCta .flex-wrapper {
    display: flex;
    flex-direction: row-reverse;
}

.form-container {
    background: rgba(0, 0, 0, 0.2);
    display: table;
    width: 100%;
}

/** loading **/

.overlay-loading {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.6);
}

.loading-loader {
    display: inline-block;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
}

.loading-loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: loading-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #a2a2a2 transparent transparent transparent;
}

.loading-loader div:nth-child(1) {
    animation-delay: -0.45s;
}

.loading-loader div:nth-child(2) {
    animation-delay: -0.3s;
}

.loading-loader div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes loading-loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/** end loading **/

.overlay-image {
    width: 28em;
    padding-top: 51.6739446870451%;
    position: relative;
}

.overlay-image img {
    left: -20%;
    bottom: 0;
    z-index: 9;
    position: absolute;
    max-width: 120%;
}

.overlay-image .image-responsive {
    display: none;
}

.download .playerlogo {
    display: block;
    width: 55%;
    margin: 50px auto;
}

.playerinfo {
    width: 22em;
    text-align: center;
    padding: 100px 30px 0 25px;
    min-height: 570px;
}

.playerinfo h2 {
    color: #2b2b2b;
    padding-bottom: 10px;
    border-bottom: solid 1px #3ca9e2;
    text-transform: uppercase;
    font-size: 2.2em;
    font-weight: 100;
}

.download .playerinfo {
    padding: 20px 30px;
}

.mainbutton, .playerCtafinal {
    display: block;
    color: #fff;
    text-align: center;
}

.playerCtafinal {
    font-weight: normal;
    font-size: 1.1em;
    margin: 30px 0;
    color: #a2a2a2;
}

.playerCtafinal.sub-header {
    margin: 30px 20px;
}

.playerCtainfo {
    margin: 50px 20px;
    color: #6d6d6d;
}

#afbtForm .playerCtafinal {
    margin-bottom: 10px !important;
}

#afbtForm .playerCtainfo {
    margin: 10px;
    color: #0a0a0a;
    line-height: 1em;
}

#afbtForm .form-group {
    padding: 0px 0;
}

#afbtForm .small-text {
    color: grey;
    font-size: .8em;
    padding-top: 0px;
}

#afbtForm p {
    margin-top: 0;
    margin-bottom: 0;
}

.category-logo {
    height: 100px;
    display: flex;
    align-items: center;
}

.category-logo img {
    max-height: 100px;
    margin: 0 auto;
}

.small-text {
    color: #808080;
    font-size: 0.8em;
    padding-top: 10px;
}

.follow-next-steps {
    color: #616161;
    font-size: 1em;
    line-height: 1.3em;
    margin: 30px;
}

.download .playerCtafinal {
    margin: 10px 0 20px 0;
    padding-bottom: 25px;
    border-bottom: solid 1px #e1e1e1;
}

.download .playerCtainfo {
    border-bottom: solid 1px #e1e1e1;
    padding-bottom: 20px;
    color: #9f9f9f;
}

.mainbutton {
    text-transform: uppercase;
    max-width: 12em;
    padding: 15px;
    margin: 25px auto 20px;
    font-size: 1em;
    font-weight: 600;
    letter-spacing: .5px;
    border-radius: 3px;
    cursor: pointer;
}

.download .mainbutton {
    margin: 20px auto 0;
}

#playerCta-login {
    background-color: #ededed;
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    color: #808080;
    padding: 20px;
}

.overlay-link {
    color: #3ca9e2;
    cursor: pointer;
}

@media screen and (max-width: 414px) {
    .form-container {
        padding: 1em;
    }

    #playerCta > h2 {
        width: 100%;
        margin-top: 25px;
    }
}

.form-register {
    display: block;
}

form {
    padding-bottom: 10px;
}

#afbtForm form {
    padding-bottom: 0px;
}

.form-control {
    display: block;
    width: 90%;
    padding: .5rem .75rem;
    font-size: 0.9rem;
    line-height: 1.5;
    color: #464a4c;
    background-color: #fff;
    background-image: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    margin-left: 5%;
}

.form-group {
    padding: 5px 0;
}

.register_button {
    margin: 20px auto 0;
    padding: 12px 50px;
}

@media screen and (max-width: 975px) {
    .hd-box {
        height: 105px;
        margin: auto;
    }

    .btn_box4 {
        display: none;
    }
}

@media screen and (max-height: 650px) {
    #playerCta {
        top: 0;
    }

    .playerinfo {
        font-size: 13px;
    }

    .playerinfo h2 {
        padding-bottom: 5px;
    }
}

@media screen and (max-width: 51em) {
    #playerCta {
        width: 90%;
        background: none;
    }

    #playerCta .flex-wrapper {
        flex-direction: column;
    }

    .overlay-image {
        width: auto;
        padding-top: 51.9765739385066%;
        background: linear-gradient(to top, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%);
    }

    .overlay-image img {
        position: absolute;
        top: 0;
    }

    .overlay-image .image-large {
        display: none;
    }

    .overlay-image .image-responsive {
        display: block;
        width: 100%;
        left: 0;
    }

    .playerinfo {
        width: auto;
        padding: 0 30px;
        background-color: white;
        min-width: auto;
        min-height: auto;
    }

    .playerinfo h2 {
        padding-top: 10px;
    }

    .playerCtainfo {
        margin: 20px 30px;
    }

    .playerCtafinal, .playerCtafinal.sub-header {
        margin: 10px 0;
    }

    .category-logo {
        height: 75px;
    }

    .category-logo img {
        max-height: 75px;
    }

    .register_button {
        margin: 10px auto 0;
    }

    #afbtForm {
        padding-bottom: 2.5em;
    }
}

#afbtForm {
    display: none;
}
