/* Big tablets to 1200px (width smaller than the 1140px row) */
@media only screen and (max-width: 1200px) {
    .row-sections {
        max-width: 100%;
        padding: 0 5%;
        margin: 0;
    }

    .hero-text-box {
        max-width: 100%;
        margin: auto;
        padding: 6%;
    }
}

/* Small tablets to big tablets: from 767px to 1023px */
@media only screen and (max-width: 1023px) {
    section {
        padding: 10px 0;
    }

    .section-newly-added-movies:after,
    .section-popular-movies:after,
    .section-trending-tv-shows:after {
        margin-top: 5%;
    }

    .hero-text-box {
        padding: 20px;
        max-width: calc(100% - 40px);
    }

    .main-nav-left {
        padding-left: 5px;
    }

    .main-nav-right {
        margin-right: 10px;
    }

    .main-nav-left span {
        display: block;
        font-size: 60%;
    }

    .main-nav-left li {
        margin-top: 3px;
        margin-left: 5px;
        float: left;
    }

    .logo {
        margin-left: 10px;
    }

    footer .row-sections {
        width: 100%;
    }

    .footer-nav {
        float: left;
    }

    .dropdown span {
        margin-left: 0;
    }

    .dropdown-content {
        transform: translateX(-70%) translatey(7%);
    }

    /*--buttons--*/

    .btn-ghost-nav:link,
    .btn-ghost-nav:visited {
        height: 30px;
        padding: 7px 10px;
    }

    .btn-full-nav:link,
    .btn-full-nav:visited {
        height: 30px;
        padding: 7px 10px;
    }

    .search-container {
        width: 110px;
    }

    .search-container input[type=text] {
        width: 84px;
    }

    /*--player--*/

    .movie-info-box {
        margin-top: 3%;
    }

    .movie-poster-info {
        width: 120px;
        margin-bottom: 120px;
    }

    .movie-info-released,
    .movie-info-genre,
    .movie-info-director,
    .movie-info-runtime,
    .movie-info-stars {
        width: 75%;
    }
}

/* Small phones to small tablets: from 481px to 767px */
@media only screen and (max-width: 767px) {
    .mobile-nav-icon {
        display: inline-block;
    }

    .header-index {
        height: 500px;
    }

    .sticky-nav {
        position: relative;
    }

    .logo {
        margin-left: 25px;
    }

    .row-nav {
        position: absolute;
    }

    .row-nav li {
        display: block;
    }

    .row-nav ul {
        float: none;
    }

    .main-nav-right {
        padding-bottom: 30px;
    }

    .main-nav-left {
        padding-top: 60px;
        padding-bottom: 10px;
        padding-left: 25px;
    }

    .main-nav-left span {
        display: inline-block;
        margin-top: 0px;
        font-size: 70%;
    }

    .main-nav-left li {
        margin-top: 10px;
        float: none;
    }

    .main-nav-right li {
        padding-left: 30px;
        padding-top: 15px;
    }

    .main-nav-right,
    .main-nav-left {
        background-color: #10141a;
        width: 100%;
        display: none;
    }

    .dropdown-content {
        position: inherit;
        background-color: #151515;
        min-width: 680%;
        transform: none;
        display: block;
        background-color: #10141a;
    }

    #myDropdown a {
        color: rgba(255, 255, 255, 0.7);
    }

    .dropdown span {
        margin-left: 5px;
    }

    .box {
        width: 33.3%;
    }

    .categories-list {
        column-count: 3;
    }

    .movie-poster {
        height: 38vw;
    }

    /*--buttons--*/

    .btn-ghost-nav:link,
    .btn-ghost-nav:visited {
        height: 35px;
        padding: 9px 41px;
    }

    .btn-full-nav:link,
    .btn-full-nav:visited {
        height: 35px;
        padding: 9px 38px;
        color: #fff;
    }

    .search-container {
        margin: 0;
        width: 120px;
    }

    .search-container input[type=text] {
        width: 94px;
    }

    /*--player--*/

    .player-container img {
        width: 100%;
    }

    .overlay-player {
        font-size: 400%;
        transition: color 0.5s;
        top: 57%;
    }

    .movie-poster-info {
        margin-top: 0;
        margin-bottom: 0;
    }

    .movie-info-subscribe {
        display: block;
        float: none;
        margin-right: 0;
    }

    .btn-ghost-info:link,
    .btn-ghost-info:visited {
        display: block;
        max-width: 370px;
        margin-left: auto;
        margin-right: auto;
        padding: 15px 0;
        border: 2px solid #57f398;
        height: auto;
        font-size: 130%;
        margin-bottom: 20px;
    }

    .movie-info-description {
        height: 100px;
    }

    .movie-info-released,
    .movie-info-genre,
    .movie-info-director,
    .movie-info-runtime,
    .movie-info-stars {
        margin-left: 15px;
        width: 95%;
    }
}

/* Small phones: from 0 to 480px */
@media only screen and (max-width: 480px) {
    .hero-text-box {
        font-size: 90%;
        margin-top: 10px;
    }

    .box {
        width: calc(50% - 22px);
    }

    .movie-poster-info {
        display: none;
    }

    .movie-info-description {
        width: 100%;
        height: auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    h5 {
        margin-left: 15px;
    }

    .imdb-bar {
        margin-left: 15px;
    }

    .categories-list {
        column-count: 2;
    }

    .movie-poster {
        height: 57vw;
    }
}
